var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    {
      staticClass: "col-md-11 mx-auto mt-10",
      attrs: { id: "cities-we-serve-container" },
    },
    [
      _c(
        "v-row",
        { staticClass: "col-12" },
        [
          _c(
            "v-col",
            {
              staticClass: "col-12 pl-0 order-md-1 order-2",
              attrs: { id: "cities-we-serve-title" },
            },
            [_c("h1", [_vm._v(_vm._s(_vm.$t("CitiesWeServe.Title")))])]
          ),
          _c(
            "v-col",
            { staticClass: "col-12 col-md-6 pl-0 order-md-2 order-3" },
            [
              _c(
                "div",
                _vm._l(_vm.cities, function (city, index) {
                  return _c(
                    "v-btn",
                    {
                      key: index,
                      staticClass: "mx-2 my-2 text-capitalize",
                      staticStyle: { "font-family": "Rubik" },
                      attrs: {
                        small: "",
                        color: "#12945F",
                        height: "45px",
                        to: { name: city.goToName },
                        outlined: "",
                      },
                    },
                    [_vm._v(" " + _vm._s(city.name) + " ")]
                  )
                }),
                1
              ),
              _c("div", { staticClass: "mt-15" }, [
                _c(
                  "div",
                  [
                    _c("zip-code-checker-component", {
                      attrs: { isUsedFromHomePage: false },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "v-col",
            { staticClass: "col-12 col-md-6 py-1 px-4 order-md-3 order-1" },
            [
              _c("v-img", {
                staticClass: "mx-auto",
                attrs: {
                  src: require("../assets/images/texas-plano-dallas-addison-nevada.webp"),
                  "lazy-src": require("../assets/images/texas-plano-dallas-addison-nevada.webp"),
                  width: _vm.$vuetify.breakpoint.mdAndUp
                    ? 450
                    : _vm.$vuetify.breakpoint.sm
                    ? 370
                    : 303,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }